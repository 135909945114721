@import "static/styles/colors";
@import "static/styles/constants";
@import "static/styles/functions";

.nosso-time {
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		font-size: $font-size-xxxl;
		color: $color-interface-dark-deep;
		margin: 128px $spacing-md $spacing-xxxl $spacing-md;
		text-align: center;

		@media (max-width: $max-width-md) {
			font-size: $font-size-xl;
			margin: $spacing-xxxl $spacing-md $spacing-xxl $spacing-md;
		}
	}

	.founders {
		margin: 0 $spacing-md $spacing-xxxl $spacing-md;
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 1000px;
		flex-wrap: wrap;

		.founder + .founder {
			margin-left: $spacing-md;
		}

		.founder {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			max-width: 448px;
			margin-top: $spacing-xxxl;

			.photo {
				width: 256px;
				height: 256px;
				border-radius: $border-radius-pill;
				border: 11px solid transparent;
				background: linear-gradient(
						57deg,
						#eb545e 0%,
						#f55 50%,
						#ff985f 100%
					)
					border-box;
				-webkit-mask-composite: xor;
				mask-composite: exclude;

				@media (max-width: $max-width-md) {
					width: 200px;
					height: 200px;
					border: 8px solid transparent;
				}
			}

			.name-and-role {
				margin: $spacing-xl 0;

				.name {
					font-size: $font-size-md;
					font-weight: $font-weight-bold;
					line-height: $line-height-md;
				}

				.role {
					color: $color-interface-dark-up;
					font-size: $font-size-sm;
					font-weight: $font-weight-semi-bold;
					line-height: $line-height-sm;
					font-family: $font-family-base;
				}
			}

			.paragraph {
				font-size: $font-size-xs;
				font-weight: $font-weight-medium;
				line-height: $line-height-xs;
			}
		}
	}

	.team {
		margin: $spacing-xxxl $spacing-md;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.title {
			font-size: $font-size-xxxl;
			margin: 0;
			margin-bottom: $spacing-md;
		}

		&-description {
			margin: $spacing-md 0;

			.paragraph {
				margin-bottom: $spacing-md;
				font-size: 18px;
				text-align: center;
			}
		}

		@media (max-width: $max-width-md) {
			.image-container {
				overflow-x: auto;
				max-width: calc($max-width-md - 70px);
			}
		}

		@media (max-width: $max-width-sm) {
			.image-container {
				overflow-x: auto;
				max-width: calc($max-width-sm - 70px);
			}
		}
	}
}
